import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { ErrorPageComponent, MetaTags } from 'ui';

const PageNotFound = () => {
  return (
    <>
      <MetaTags title="404" />
      <div className="mx-auto max-w-[600px] px-4">
        <ErrorPageComponent
          content="Sorry, the page you visited does not exist."
          buttonLink="/events"
          buttonText="Go to Dashboard"
        />
      </div>
    </>
  );
};

export default PageNotFound;

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}
